import i18n from '@base/i18n'

export const host = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_DOMAIN : 'localhost:8000'

export const wsHost = `wa://${host}/`

// Envs which we will use for our app, deployed to Google Play and App Store
const standaloneAppSchemeEnvs = ['PRODUCTION']
export const useStandaloneMobileAppScheme = standaloneAppSchemeEnvs.includes(process.env.VUE_APP_SERVER_TYPE)

// In which envs we are using deep linking for mobile app
const mobileAppSchemeEnvs = ['DEV', 'STAGING']
export const useMobileAppScheme = mobileAppSchemeEnvs.includes(process.env.VUE_APP_SERVER_TYPE)

const getMobileAppScheme = () => {
  if (useStandaloneMobileAppScheme)
    return 'flea-lover://'
  if (process.env.VUE_APP_SERVER_TYPE === 'STAGING')
    return 'exp://exp.host/@arossoftware/flea-lover/--'
  if (process.env.VUE_APP_SERVER_TYPE === 'DEV')
    return `exp://${process.env.VUE_APP_LOCAL_IP}/--`
}

export const mobileAppScheme = getMobileAppScheme()

export const webConfig = {
  baseURL: process.env.NODE_ENV === 'production' ? `https://${host}` : `http://${host}`,
}
export const userConfig = {
  baseURL: `${webConfig.baseURL}/accounts`
}

export const marketConfig = {
  baseURL: `${webConfig.baseURL}/markets`
}

export const apiConfig = {
  baseURL: `${webConfig.baseURL}/api/v1`
}

export const adminApiConfig = {
  baseURL: `${apiConfig.baseURL}/admin`
}

export const userApiConfig = {
  baseURL: `${apiConfig.baseURL}/user`
}

export const IS_GLOBAL_APP = process.env.VUE_APP_GLOBAL_APP ? process.env.VUE_APP_GLOBAL_APP.toLowerCase() === 'true' : false

export const loaderOptions = {
  color: '#4b8bff',
  backgroundColor: '#000',
  opacity: 0.1
}

export const RECAPTCHA_SITE_KEY = process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY

export const INSTAGRAM_APP_ID = process.env.VUE_APP_INSTAGRAM_APP_ID

const USE_RECAPTCHA_ENVS = ['PRODUCTION']
export const USE_RECAPTCHA = USE_RECAPTCHA_ENVS.includes(process.env.VUE_APP_SERVER_TYPE)

export const TIME_TOKEN_REFRESH_MILLISECONDS = 100 * 86400 * 1000  // Token will refresh in 100 days

export const REDIRECT_COUNTDOWN_SECONDS = 4

export const USER = 1
export const ADMIN = 2

export const USER_TYPES = {
  USER: USER,
  ADMIN: ADMIN,
}

export const USER_TYPE_OPTIONS = [
  { name: i18n.t(`admin.multiselect.single_label.user_type.${USER}`), type: USER },
  { name: i18n.t(`admin.multiselect.single_label.user_type.${ADMIN}`), type: ADMIN },
]

export const APP_DOMAIN = IS_GLOBAL_APP ? 'app.flealover.com' : 'loppeonline.dk'
export const APP_NAME = IS_GLOBAL_APP ? 'FleaLover' : 'LoppeOnline'
export const SYSTEM_NAME = IS_GLOBAL_APP ? 'FleaLover' : 'NoviPOS'

// Barcode settings
export const BARCODE_QUANTITY = 65
export const BARCODE_COLUMNS = 5

// Field length settings
export const MIN_NAME_FIELD_LENGTH = 4
export const MAX_NAME_FIELD_LENGTH = 20
export const MIN_USERNAME_LENGTH = 4
export const MAX_USERNAME_LENGTH = 20
export const MIN_PASSWORD_LENGTH = 8
export const MIN_POSTAL_CODE_LENGTH = 3
export const MAX_POSTAL_CODE_LENGTH = 12
export const MAX_BANK_ACCOUNT_LENGTH = 10
export const MAX_BANK_ACCOUNT_NORWAY_LENGTH = 11
export const MAX_BANK_ACCOUNT_GB_LENGTH = 8
export const MAX_BANK_ACCOUNT_GERMANY_LENGTH = 22
export const BANK_REG_NUMBER_LENGTH = 4
export const GB_BANK_REG_NUMBER_LENGTH = 6

export const MIN_SHELF_AMOUNT = 1
export const MAX_SHELF_AMOUNT = 1000

export const MAX_SCREEN_WIDTH_MOBILE = 575
export const ECONOMIC_COUNTRIES_ARRAY = ['DK', 'SE', 'GL']
export const COUNTRIES_ARRAY_ONLY = IS_GLOBAL_APP ? ['GB', 'DE', 'DK', 'SE', 'NO', 'GL', 'BR', 'FI', 'FR', 'IT', 'MX', 'NL', 'ES', 'US'] : ['DK', 'SE', 'NO', 'GL']
export const PHONE_DEFAULT_COUNTRY_CODE = 'DK'
export const PHONE_COUNTRY_CODE_MAPPING = {
  DA: 'DK',
  DE: 'DE',
  EN: 'GB',
}
export const PHONE_NUMBER_INPUT_TRANSLATIONS = {
    DA: {
      countrySelectorLabel: 'Landekode',
      countrySelectorError: 'Vælg et land',
      phoneNumberLabel: 'Telefonnummer',
      example: 'Eksempel:'
    },
    EN: {
      countrySelectorLabel: 'Country Code',
      countrySelectorError: 'Select a country',
      phoneNumberLabel: 'Phone number',
      example: 'Example:'
    },
    DE: {
      countrySelectorLabel: 'Ländervorwahl',
      countrySelectorError: 'Land auswählen',
      phoneNumberLabel: 'Telefonnummer',
      example: 'Beispiel:'
    }
}
export const LANGUAGES = [
  {
    name: i18n.t('multiselect.single_label.language.da'),
    code: 'da',
  },
  {
    name: i18n.t('multiselect.single_label.language.de'),
    code: 'de',
  },
  {
    name: i18n.t('multiselect.single_label.language.en'),
    code: 'en',
  },
]
export const NORWAY_COUNTRY = 'NO'
export const GB_COUNTRY = 'GB'
export const GERMANY_COUNTRY = 'DE'

export const AVAILABLE_COUNTRIES = [
  {
    name: i18n.t('multiselect.single_label.country.dk'),
    code: 'DK',
    genericProductNameUsed: 'Kommissionssalg (brugt)',
    genericProductNameNew: 'Kommissionssalg'
  },
  {
    name: i18n.t('multiselect.single_label.country.se'),
    code: 'SE',
    genericProductNameUsed: 'Provisionsförsäljning (begagnad vara)',
    genericProductNameNew: 'Provisionsförsäljning'
  },
  {
    name: i18n.t('multiselect.single_label.country.no'),
    code: 'NO',
    genericProductNameUsed: 'Kommissionssalg (brukt)',
    genericProductNameNew: 'Kommissionssalg'
  },
  {
    name: i18n.t('multiselect.single_label.country.gl'),
    code: 'GL',
    genericProductNameUsed: 'Kommissionssalg (brugt)',
    genericProductNameNew: 'Kommissionssalg'
  },
]
if (IS_GLOBAL_APP) {
  AVAILABLE_COUNTRIES.push(...[
    {
      name: i18n.t('multiselect.single_label.country.gb'),
      code: 'GB',
      genericProductNameUsed: 'Commissioned sale (used)',
      genericProductNameNew: 'Commissioned sale'
    },
    {
      name: i18n.t('multiselect.single_label.country.de'),
      code: 'DE',
      genericProductNameUsed: 'Kommissionsverkäufe (gebraucht)',
      genericProductNameNew: 'Kommissionsverkäufe'
    },
    {
      name: i18n.t('multiselect.single_label.country.br'),
      code: 'BR',
      genericProductNameUsed: 'Ventas por comisión (usado)',
      genericProductNameNew: 'Ventas a comisión'
    },
    {
      name: i18n.t('multiselect.single_label.country.fi'),
      code: 'FI',
      genericProductNameUsed: 'Välitysmyynti (käytetty)',
      genericProductNameNew: 'Komission myynti'
    },
    {
      name: i18n.t('multiselect.single_label.country.fr'),
      code: 'FR',
      genericProductNameUsed: 'Ventes à commission (occasion)',
      genericProductNameNew: 'Ventes à commission'
    },
    {
      name: i18n.t('multiselect.single_label.country.it'),
      code: 'IT',
      genericProductNameUsed: 'Vendite su commissione (usato)',
      genericProductNameNew: 'Vendite su commissione'
    },
    {
      name: i18n.t('multiselect.single_label.country.mx'),
      code: 'MX',
      genericProductNameUsed: 'Ventas por comisión (usado)',
      genericProductNameNew: 'Ventas a comisión'
    },
    {
      name: i18n.t('multiselect.single_label.country.nl'),
      code: 'NL',
      genericProductNameUsed: 'Commissieverkoop (gebruikt)',
      genericProductNameNew: 'Verkoop op commissiebasis'
    },
    {
      name: i18n.t('multiselect.single_label.country.es'),
      code: 'ES',
      genericProductNameUsed: 'Ventas por comisión (usado)',
      genericProductNameNew: 'Ventas a comisión'
    },
    {
      name: i18n.t('multiselect.single_label.country.us'),
      code: 'US',
      genericProductNameUsed: 'Commission sales (used)',
      genericProductNameNew: 'Commission sales'
    },
  ])
}

export const BOOLEAN_OPTIONS = [
  { value: true, name: i18n.t('multiselect.single_label.boolean.true') },
  { value: false, name: i18n.t('multiselect.single_label.boolean.false') }
]

export const USED = 1
export const NEW = 2
export const USED_PREFIX = "kb"
export const NEW_PREFIX = "kn"

export const VAT_RATE_TYPES = [
  {
    type: USED, name: i18n.t(`multiselect.single_label.customers.vat_rate.${USED}`),
    prefix: 'kb'
  },
  {
    type: NEW, name: i18n.t(`multiselect.single_label.customers.vat_rate.${NEW}`),
    prefix: 'kn'
  },
]

export const VAT_PERCENTS = {
  DK: [{ value: 0, name: "0%" }, { value: 25, name: "25%" }],
  SE: [{ value: 0, name: "0%" }, { value: 25, name: "25%" }],
  NO: [{ value: 0, name: "0%" }, { value: 25, name: "25%" }],
  GL: [{ value: 0, name: "0%" }, { value: 25, name: "25%" }],
  GB: [{ value: 0, name: "0%" }, { value: 20, name: "20%" }],
  DE: [{ value: 0, name: "0%" }, { value: 19, name: "19%" }],
  BR: [{ value: 0, name: "0%" }, { value: 12, name: "12%" }, { value: 17, name: "17%", }],
  FI: [{ value: 0, name: "0%" }, { value: 10, name: "10%" }, { value: 14, name: "14%" }, { value: 25.5, name: "25.5%" }],
  FR: [{ value: 0, name: "0%" }, { value: 10, name: "10%" }, { value: 20, name: "20%", }],
  IT: [{ value: 0, name: "0%" }, { value: 10, name: "10%" }, { value: 22, name: "22%", }],
  MX: [{ value: 0, name: "0%" }, { value: 8, name: "8%" }, { value: 11, name: "11%" }, { value: 16, name: "16%" }],
  NL: [{ value: 0, name: "0%" }, { value: 9, name: "9%" }, { value: 21, name: "21%", }],
  ES: [{ value: 0, name: "0%" }, { value: 10, name: "10%" }, { value: 21, name: "21%", }],
  US: [{ value: 0, name: "0%" }],
}

const MONDAY = 1
const TUESDAY = 2
const WEDNESDAY = 3
const THURSDAY = 4
const FRIDAY = 5
const SATURDAY = 6
const SUNDAY = 7

export const OPENING_HOURS = [
  {
    weekdayCode: MONDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${MONDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: TUESDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${TUESDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: WEDNESDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${WEDNESDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: THURSDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${THURSDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: FRIDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${FRIDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: SATURDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${SATURDAY}`),
    fromHour: '',
    toHour: ''
  },
  {
    weekdayCode: SUNDAY,
    weekdayName: i18n.t(`multiselect.single_label.weekday.${SUNDAY}`),
    fromHour: '',
    toHour: ''
  },
]

export const BALANCE_INITIAL_BALANCE = 1
export const BALANCE_PAYMENT = 2
export const BALANCE_CASH_OUT = 3
export const BALANCE_ELECTRONIC = 4
export const BALANCE_BANK_TRANSFER = 5
export const BALANCE_PURCHASE = 6
export const BALANCE_RENT = 7
export const BALANCE_OTHER = 8

export const PAYMENT_TYPES = [
  {
    code: BALANCE_INITIAL_BALANCE,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_INITIAL_BALANCE}`),
  },
  {
    code: BALANCE_PAYMENT,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_PAYMENT}`),
  },
  {
    code: BALANCE_CASH_OUT,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_CASH_OUT}`),
  },
  {
    code: BALANCE_ELECTRONIC,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_ELECTRONIC}`),
  },
  {
    code: BALANCE_BANK_TRANSFER,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_BANK_TRANSFER}`),
  },
  {
    code: BALANCE_PURCHASE,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_PURCHASE}`),
  },
  {
    code: BALANCE_RENT,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_RENT}`),
  },
  {
    code: BALANCE_OTHER,
    name: i18n.t(`multiselect.single_label.balance_transactions.${BALANCE_OTHER}`),
  },
]

// Products

export const PRODUCT_NAME_MAX_LENGTH = 24
export const PRODUCT_DESCRIPTION_MAX_LENGTH = 200

export const SHEET_DRAFT = 1
export const SHEET_READY = 2
export const SHEET_PROCESSING = 3
export const SHEET_ACTIVE = 4
export const SHEET_PRINTED = 5

export const PRODUCT_SHEET_STATUSES = [
  {
    code: SHEET_DRAFT,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_DRAFT}`),
  },
  {
    code: SHEET_READY,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_READY}`),
  },
  {
    code: SHEET_PROCESSING,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_PROCESSING}`),
  },
  {
    code: SHEET_ACTIVE,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_ACTIVE}`),
  },
  {
    code: SHEET_PRINTED,
    name: i18n.t(`multiselect.single_label.product_sheets.${SHEET_PRINTED}`),
  },
]

export const PRODUCT_DRAFT = 1
export const PRODUCT_READY = 2
export const PRODUCT_PROCESSING = 3
export const PRODUCT_ACTIVE = 4
export const PRODUCT_PRINTED = 5
export const PRODUCT_SOLD = 6
export const PRODUCT_CHECKED_OUT = 7

export const PRODUCT_STATUSES = [
  {
    code: PRODUCT_DRAFT,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_DRAFT}`),
  },
  {
    code: PRODUCT_READY,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_READY}`),
  },
  {
    code: PRODUCT_PROCESSING,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_PROCESSING}`, { systemName: SYSTEM_NAME }),
  },
  {
    code: PRODUCT_ACTIVE,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_ACTIVE}`, { systemName: SYSTEM_NAME }),
  },
  {
    code: PRODUCT_PRINTED,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_PRINTED}`),
  },
  {
    code: PRODUCT_SOLD,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_SOLD}`),
  },
  {
    code: PRODUCT_CHECKED_OUT,
    name: i18n.t(`multiselect.single_label.products.${PRODUCT_CHECKED_OUT}`),
  },
]

export const EDITABLE_STATUSES = [SHEET_DRAFT, PRODUCT_DRAFT, SHEET_READY, PRODUCT_READY]
export const ACTIVE_STATUSES = [PRODUCT_ACTIVE, PRODUCT_PRINTED]
export const SOLD_STATUSES = [PRODUCT_SOLD]

// Booking

export const AVAILABLE = 0
export const BOOKED = 1
export const BOOKED_PAID = 2
export const RESERVED = 3

export const BOOKING_STATUSES = [
  {
    code: AVAILABLE,
    name: i18n.t(`multiselect.single_label.booking.${AVAILABLE}`),
    class: 'available'
  },
  {
    code: BOOKED,
    name: i18n.t(`multiselect.single_label.booking.${BOOKED}`),
    class: 'booked'
  },
  {
    code: BOOKED_PAID,
    name: i18n.t(`multiselect.single_label.booking.${BOOKED_PAID}`),
    class: 'booked-paid'
  },
  {
    code: RESERVED,
    name: i18n.t(`multiselect.single_label.booking.${RESERVED}`),
    class: 'reserved'
  },
]

const INFO = 1
const WARNING = 2
const ERROR = 3

export const LEVEL_CHOICES = {
  [INFO]: 'info',
  [WARNING]: 'warning',
  [ERROR]: 'danger',
}

export const DRAFT = 1
export const SENT_TO_ECONOMIC = 2
export const FINALIZED = 3
export const SENT_TO_CUSTOMER = 4

export const PAYMENT_STATUSES = [
  {
    code: DRAFT,
    name: i18n.t(`multiselect.single_label.pisp.${DRAFT}`),
  },
  {
    code: SENT_TO_ECONOMIC,
    name: i18n.t(`multiselect.single_label.pisp.${SENT_TO_ECONOMIC}`),
  },
  {
    code: FINALIZED,
    name: i18n.t(`multiselect.single_label.pisp.${FINALIZED}`),
  },
  {
    code: SENT_TO_CUSTOMER,
    name: i18n.t(`multiselect.single_label.pisp.${SENT_TO_CUSTOMER}`),
  },
]

export const SDC = 'sdc'
export const BEC = 'bec'
export const DANSKE_BANK = 'danske_bank'
export const NORDEA = 'nordea'
export const ISO_20022 = 'iso_20022'
export const BANKDATA = 'bankdata'

export const AVAILABLE_BANK_FORMATS = [
  {
    code: SDC,
    name: i18n.t(`multiselect.single_label.bank_export.${SDC}`),
  },
  {
    code: BEC,
    name: i18n.t(`multiselect.single_label.bank_export.${BEC}`),
  },
  {
    code: DANSKE_BANK,
    name: i18n.t(`multiselect.single_label.bank_export.${DANSKE_BANK}`),
  },
  {
    code: NORDEA,
    name: i18n.t(`multiselect.single_label.bank_export.${NORDEA}`),
  },
  {
    code: ISO_20022,
    name: i18n.t(`multiselect.single_label.bank_export.${ISO_20022}`),
  },
  {
    code: BANKDATA,
    name: i18n.t(`multiselect.single_label.bank_export.${BANKDATA}`),
  },
]

export const DEFAULT_PRINT = 1
export const ROLL_PRINT = 2

export const PRINT_TYPES = [
  {
    code: DEFAULT_PRINT,
    name: i18n.t(`multiselect.single_label.print_type.${DEFAULT_PRINT}`),
  },
  {
    code: ROLL_PRINT,
    name: i18n.t(`multiselect.single_label.print_type.${ROLL_PRINT}`),
  },
]

export const DEFAULT_DATEPICKER_SHORTCUTS = [
  { key: 'thisWeek', label: i18n.t('datepicker.shortcuts.this_week'), value: 'isoWeek' },
  { key: 'lastWeek', label: i18n.t('datepicker.shortcuts.last_week'), value: '-isoWeek' },
  { key: 'last7Days', label: i18n.t('datepicker.shortcuts.last_7_days'), value: 7 },
  { key: 'last30Days', label: i18n.t('datepicker.shortcuts.last_30_days'), value: 30 },
  { key: 'thisMonth', label: i18n.t('datepicker.shortcuts.this_month'), value: 'month' },
  { key: 'lastMonth', label: i18n.t('datepicker.shortcuts.last_month'), value: '-month' },
  { key: 'thisYear', label: i18n.t('datepicker.shortcuts.this_year'), value: 'year' },
  { key: 'lastYear', label: i18n.t('datepicker.shortcuts.last_year'), value: '-year' }
]

export const DEFAULT_65_BARCODES = 1
export const EXTENDED_40_BARCODES = 2
export const EXTENDED_24_BARCODES = 3

export const SELECTABLE_BOOKING_STATUSES = BOOKING_STATUSES.slice(1)

export const MIN_USER_NUMBER_VALUE = 1000
export const MAX_USER_NUMBER_VALUE = 10000

export const DATE_FORMAT = 'DD-MM-YYYY'
export const DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'

export const ADMIN_SECTION_PATH = '/admin_section#/'
export const USER_SECTION_PATH = '/user#/'
export const LOGIN_SECTION_PATH = '/login/'
export const DJANGO_ADMIN_PATH_NAME = 'admin/'

export const NOVIPOS_URL = 'https://novipos.com'
export const MOBILE_APP_HELP_URLS = {
  DA: 'https://novipos.com/loppeonline-flealover-vejledninger/',
  DE: 'https://flealover.com/de/kunden-guides/',
  EN: 'https://flealover.com/customer-guides/',
}

export const APP_STORE_URL = 'https://apps.apple.com/us/app/flea-lover/id1567180764'
export const PLAY_MARKET_URL = 'https://play.google.com/store/apps/details?id=com.flealover'

export const INSTAGRAM_MEDIA_TYPE_IMAGE = 'IMAGE'
export const INSTAGRAM_MEDIA_TYPE_VIDEO = 'VIDEO'
export const INSTAGRAM_MEDIA_TYPE_CAROUSEL = 'CAROUSEL_ALBUM'
