<script>
  export default {
    data () {
      return {
        objectSuccess: false,
      }
    },

    methods: {
      showConfirmModal (obj, modalId, payload = null) {
        this.selectedObject = this.$_.cloneDeep(obj)
        this.payload = payload
        this.$bvModal.show(modalId)
      },
      confirmObjectAction (
        action,
        idFieldName,
        successMessage,
        modalId,
      ) {
        // Confirm the action for the object
        //
        // action: the action that is going to be dispatched
        //
        // idFieldName: the name of the selectedObject key that
        // contains the ID
        //
        // successMessage: the message that is displayed
        // on success
        //
        // modalId: the ID of the modal window that should be
        // hidden on success
        return new Promise(resolve => {
          if (this.loading)
            return
          this.loading = true
          this.loader = this.$loading.show(this.$_loaderOptions)
          const payload = this.payload
            ? { [idFieldName]: this.selectedObject[idFieldName], ...this.payload }
            : this.selectedObject[idFieldName]
          this.$store.dispatch(action, payload)
            .then(() => {
              this.objectSuccess = true
              if (modalId) {
                this.$nextTick(() => {
                  this.$bvModal.hide(modalId)
                })
              }
              this.$notify({
                group: 'app',
                type: 'success',
                title: this.$t('notifications.title.success'),
                text: successMessage
              })
            })
            .catch((error) => {
              this.$_notifyError(error, this)
            })
            .finally(() => {
              this.loader.hide()
              this.loading = false
              if (this.objectSuccess)
                this.$emit('objectSuccess')
              this.objectSuccess = false
              resolve()
            })
        })
      },
    }
  }
</script>
