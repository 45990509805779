import axios from 'axios'
import { adminApiConfig, userConfig, webConfig } from '@base/configs'


export const adminChangePassword = axios.create({
  method: 'post',
  baseURL: `${webConfig.baseURL}/password/change/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getAdmin = axios.create({
  method: 'get',
  baseURL: `${userConfig.baseURL}/admin/`,
  headers: { 'Accept': 'application/json' },
})

export const updateAdmin = axios.create({
  method: 'patch',
  baseURL: `${userConfig.baseURL}/admin/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Market

export const getAdminMarket = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/markets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const updateMarketSettings = axios.create({
  baseURL: `${adminApiConfig.baseURL}/markets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const marketLogo = axios.create({
  baseURL: `${adminApiConfig.baseURL}/market_logo/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  }
})

export const deleteMarketImage = axios.create({
  method: 'delete',
  baseURL: `${adminApiConfig.baseURL}/images/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const createMarketImage = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/images/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'multipart/form-data',
  }
})

// Customer

export const getCustomer = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/customer/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const getCustomerList = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/customers/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getAllCustomers = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/customers/summary/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const editCustomer = axios.create({
  baseURL: `${adminApiConfig.baseURL}/customers/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const addCustomer = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/customers/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const deleteCustomer = axios.create({
  method: 'delete',
  baseURL: `${adminApiConfig.baseURL}/customers/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const sendEmailConfirmation = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/send_email_confirmation/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const resetCustomerPassword = axios.create({
  method: 'patch',
  baseURL: `${adminApiConfig.baseURL}/reset_customer_password/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const verifyCustomer = axios.create({
  method: 'patch',
  baseURL: `${adminApiConfig.baseURL}/verify_customer/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// Payments

export const getPaymentsInfo = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/payments_info/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getPaymentsInfoXLSX = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/payments_info/xlsx/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getCustomerPaymentReports = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/customer_payment_report/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Shelves

export const getShelfList = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/shelves/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const editShelf = axios.create({
  method: 'put',
  baseURL: `${adminApiConfig.baseURL}/shelves/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const addShelf = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/shelves/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const deleteShelf = axios.create({
  method: 'delete',
  baseURL: `${adminApiConfig.baseURL}/shelves/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// Campaigns

export const getCampaignList = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/campaigns/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const editCampaign = axios.create({
  method: 'put',
  baseURL: `${adminApiConfig.baseURL}/campaigns/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const addCampaign = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/campaigns/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const deleteCampaign = axios.create({
  method: 'delete',
  baseURL: `${adminApiConfig.baseURL}/campaigns/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// Shelf Bookings

export const getShelfBookingList = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/shelf_bookings/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const editShelfBooking = axios.create({
  method: 'put',
  baseURL: `${adminApiConfig.baseURL}/shelf_bookings/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const addShelfBooking = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/shelf_bookings/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

export const deleteShelfBooking = axios.create({
  method: 'delete',
  baseURL: `${adminApiConfig.baseURL}/shelf_bookings/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

// Customer Bookings

export const getCustomerBookings = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/customer_bookings/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const salesPeriods = axios.create({
  baseURL: `${adminApiConfig.baseURL}/payments_info/sales_periods/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const bankExportTransactions = axios.create({
  baseURL: `${adminApiConfig.baseURL}/payments_info/bank_export_transactions/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const economicLayouts = axios.create({
  baseURL: `${adminApiConfig.baseURL}/economic_layouts/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const marketMessages = axios.create({
  baseURL: `${adminApiConfig.baseURL}/market_messages/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Reports

export const bookingsReport = axios.create({
  baseURL: `${adminApiConfig.baseURL}/bookings_report/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const customerCampaignsReport = axios.create({
  baseURL: `${adminApiConfig.baseURL}/customer_campaigns_report/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Product sheets

export const customerProductSheets = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const createCustomerProductSheet = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const updateCustomerProductSheet = axios.create({
  method: 'patch',
  baseURL: `${adminApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const deleteCustomerProductSheet = axios.create({
  method: 'delete',
  baseURL: `${adminApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})


// Products

export const getCustomerProductSaleLines = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/customer_product_sale_lines/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const getCustomerProducts = axios.create({
  method: 'get',
  baseURL: `${adminApiConfig.baseURL}/customer_products/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const productsCheckout = axios.create({
  method: 'patch',
  baseURL: `${adminApiConfig.baseURL}/products/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

export const createCustomerProduct = axios.create({
  method: 'post',
  baseURL: `${adminApiConfig.baseURL}/product_sheets/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Product campaigns

export const productCampaigns = axios.create({
  baseURL: `${adminApiConfig.baseURL}/product_campaigns/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})

// Balance Transactions

export const balanceTransactions = axios.create({
  baseURL: `${adminApiConfig.baseURL}/balance_transactions/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
})